//  libraries
import React from 'react'
import PropTypes from 'prop-types';

//  styles
import { Button } from './customButton.style'

const CustomButton = ({ text, ...rest }) => {
  return (
    <Button {...rest} > {text} </Button>
  )
}

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CustomButton