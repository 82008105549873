//  libraries
import React, { useState } from 'react'
import ReactGA from 'react-ga'
import { useStaticQuery, graphql } from 'gatsby'
// import Fade from 'react-reveal/Fade'
import { Icon } from 'react-icons-kit'
import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import shortid from 'short-uuid'

//  components
import CustomButton from 'components/CustomButton'
// import NewDropdown from 'components/NewDropdown'

//  styles
import {
  Container,
  TopBar,
  Nav,
  CustomLink,
  MobileMenu,
  TopMessage,
  NavButtonsContainer
} from './navbar.style'
// import { menu } from 'react-icons-kit/feather/menu'
// import { x } from 'react-icons-kit/feather/x'
// import navStyles from './index.module.css'
// import DropdownMenuItem from './DropdownMenuItem'

// const NewFeatureTag = () => <p className="new-feature-tag">New Feature!</p>

function Tabs({ items, isMobile, ...rest }) {
  if (typeof window === 'undefined') return null

  // const capacityPreference = localStorage.getItem(
  //   'EDGE_LOGISTICS_CAPACITY_PREFERENCE'
  // )

  const toPath = (item) => {
    // if (item.label === 'Capacity' && capacityPreference) {
    //   return `/capacity-${capacityPreference}`
    // }

    return item.path
  }

  return (
    <Nav {...rest}>
      <ul>
        {items.map((item) => {
          return (
            <li key={shortid.generate()}>
              <Link to={toPath(item)} activeClassName="active" partiallyActive>
                {item.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </Nav>
  )
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired
}

const Navbar2 = () => {
  const data = useStaticQuery(graphql`
    query NavbarItems {
      strapiLayout {
        navbar {
          navItems {
            path
            label
          }
          topMessage
          numberTop
        }
        logoEdgeBlack {
          publicURL
        }
      }
    }
  `)

  const { navbar, logoEdgeBlack } = data.strapiLayout

  const [state, setState] = useState({
    mobileMenu: false
  })

  const toggleHandler = () => {
    setState({
      ...state,
      mobileMenu: !state.mobileMenu
    })
  }

  const handleOpenLogin = (event) => {
    window.open(process.env.GATSBY_CAPACITY_LANDING_URL, '_blank')
    ReactGA.event({
      category: 'Outbound Link',
      action: 'click',
      label: process.env.GATSBY_CAPACITY_LANDING_URL
    })
  }

  // const handleCarrierSignUp = (event) => {
  //   ReactGA.event({
  //     category: 'Outbound Link',
  //     action: 'click',
  //     label: event.target.href
  //   })
  //   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //     window.open(process.env.GATSBY_CAPACITY_SIGN_UP_URL)
  //   } else {
  //     window.gtag('event', 'conversion', {
  //       send_to: 'AW-342968788/c4LBCILn1o4DENSTxaMB',
  //       event_callback: () => {
  //         window.open(process.env.GATSBY_CAPACITY_SIGN_UP_URL)
  //       }
  //     })
  //   }
  // }

  const handleContact = (event) => {
    navigate('/request-quote')
    ReactGA.event({
      category: 'Outbound Link',
      action: 'click',
      label: event.target.href
    })
  }

  return (
    <Container>
      <TopMessage>
        <p>
          {navbar.topMessage}
          <strong>
            <a
              href={`tel:+1-${navbar.numberTop.substr(
                navbar.numberTop.indexOf('-') + 1
              )}`}
            >
              {navbar.numberTop}
            </a>
          </strong>
        </p>
      </TopMessage>
      <TopBar>
        <CustomLink to="/">
          <img src={logoEdgeBlack.publicURL} alt="logo" />
        </CustomLink>
        <Tabs className="desktop-menu" items={navbar.navItems} />
        <NavButtonsContainer>
          <CustomButton
            className="hide request-button"
            onClick={handleContact}
            text="Request a Quote"
          />
          <CustomButton
            className="hide login-button"
            onClick={handleOpenLogin}
            text="Login"
          />
          {/* <NewDropdown className="hide dropdown-button" onClick={handleClick} /> */}
        </NavButtonsContainer>
        {/* <MenuButton onClick={toggleHandler}>
          {state.mobileMenu ? (
            <Icon className="bar" icon={x} />
          ) : (
            <Fade>
              <Icon className="close" icon={menu} />
            </Fade>
          )}
        </MenuButton> */}{' '}
        {/** CAPACITY */}
      </TopBar>
      <MobileMenu className={`${state.mobileMenu ? 'active' : ''}`}>
        <Tabs items={navbar.navItems} isMobile className="mobile-menu" />
        <CustomButton
          className="request-button"
          onClick={handleContact}
          text="Request a Quote"
        />
        <CustomButton
          className="login-button"
          onClick={handleOpenLogin}
          text="Log In"
        />
        {/* <CustomButton
          className="sign-up-button"
          onClick={handleCarrierSignUp}
          text="Carrier Sign Up"
        /> */}{' '}
        {/** CAPACITY */}
      </MobileMenu>
    </Container>
  )
}

export default Navbar2
