//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
//  containers
import Navbar from 'containers/Navbar2'
import Footer from 'containers/Footer'
import ScrollSnap from 'components/ScrollSnap'

//  styles
import { appTheme } from 'styles/theme'
import { GlobalStyle } from 'styles/GlobalStyles'

const Container = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const BackgroundImage = styled.div`
  img {
    width: 100%;
    height: 925px;
  }
`;

const Layout = ({ children, withoutScrollSnap, className }) => {

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyle />
      <Container>
        <Navbar />
        <BackgroundImage id="background-image"></BackgroundImage>
        <ScrollSnap disabled={withoutScrollSnap} className={className}>
          {children}
          <Footer />
        </ScrollSnap>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
