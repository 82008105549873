import styled from 'styled-components'
import { Link } from 'gatsby'

export const Nav = styled.nav`
  ul {
    display: flex;
    text-align: center;

    li a {
      font-weight: 400;
      text-decoration: none;
      font-size: 1.6rem;
      adding: 14px 16px;
      display: inline-block;
    }
  }

  &.desktop-menu {
    flex: 1;
    margin-right: 1.6rem;
    visibility: visible;
    transition: all 0.3s ease;
    @media only screen and (max-width: 1366px) {
      margin-right: 1.3rem;
    }
    @media only screen and (max-width: 1100px) {
      display: none;
    }
    ul {
      display: flex;
      column-gap: 37px;
      align-items: center;
      margin-left: 20px;
      li {
        a {
          color: #101418;
        }
        @media only screen and (max-width: 1440px) {
          margin: 0;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.mobile-menu {
    ul {
      flex-direction: column;
      padding-bottom: 2rem;
    }

    li {
      a {
        display: block;
        padding: 1.3rem 0.5rem;
        border-radius: 5px;
        transition: all 0.3s ease;
        font-weight: 600;
        font-size: 20px;
        color: white;
      }

      &:hover {
        a {
          padding: 1.3rem 1.5rem;
          color: #000;
          background-color: #fff;
        }
      }
    }

    .submenu {
      font-family: Poppins, sans-serif;
      font-size: 1.6rem;
      font-weight: 600;
      font-size: 20px;
      transition: all 0.3s ease;
      color: #fff;
      ul {
        list-style-type: square;
        display: inline-block;
        display: flex;
        flex-align: column;
        align-items: center;
        margin: none;
        padding-bottom: 0 !important;
        li {
          text-align: left;
          a {
            font-size: 0.8em;
          }
        }
      }
      hr {
        width: 50%;
        border-top: 1px solid #fff;
      }
    }
  }

  .active {
    color: #147efb;
    padding: 0.8rem 1.5rem 0.5rem 1.5rem;
    border-bottom: solid 2px #147efb;
  }

  p.new-feature-tag {
    width: 70px;
    margin-top: -20px;
    border-radius: 6px;
    text-align: center;
    font-size: 8px;
    background-color: #147efb;
    height: 12px;
    color: #ffff;
    position: relative;
    top: 8px;
    left: 30px;

    @media (max-width: 1024px) {
      top: 28px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: white
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.17),
      rgba(185, 193, 204, 0.17)
    )
    0% 0% no-repeat padding-box;

  @media screen and (min-width: 1920px) {
    left: 50%;
    transform: translateX(-50%);
  }
`

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* max-width: 135rem; */
  height: 80px;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 100px;
    padding: 25px 37px 25px 36px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    padding: 25px 52px 25px 50px;
    height: 100px;
  }

  @media screen and (min-width: 1440px) {
    height: 106px;
    padding: 32px 51.57px 22.51px 62.09px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1100px) {
    justify-content: space-between;
  }

  .menubar {
    display: none;
    @media only screen and (max-width: 1100px) {
      display: inline-flex;
      padding: 0;
      min-width: 35px;

      svg {
        width: 27px;
        height: auto;
      }
    }
  }
`

export const MenuButton = styled.button`
  display: none;

  @media only screen and (max-width: 1100px) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background: none;
    margin: 0;
    /* margin-top: 10px; */
    padding: 0;
    width: 24px;
    height: 24px;
    svg {
      width: 20px;
      height: auto;
      color: #383f48;
    }
  }
`

export const TopMessage = styled.div`
  background-color: rgb(55, 63, 73);
  height: 24px;
  display: flex;
  padding: 3px 0;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    @media (min-width: 1440px) {
      font-size: 1.6rem;
    }
  }

  a {
    color: #147efb !important;
  }
`

export const CustomLink = styled(Link)`
  padding: 0 !important;
  width: 80px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 375px) and (max-width: 767px) {
    width: 80px;
    height: 40px;
  }

  @media (min-width: 768px) and (max-width: 1439px) {
    width: 100px;
    height: 50px;
  }
  @media (min-width: 1440px) {
    width: 101.09px;
    height: 51.49px;
  }
`

export const MobileMenu = styled.div`
  display: none;
  z-index: 999;
  position: absolute;
  top: 0;
  @media only screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 27px 30px 40px 30px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 14rem;
    background-color: #000000;
    transition: all 0.3s ease;
    &.active {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 3px 12px;
    }
  }

  .request-button {
    border: 4px solid #147efb;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #147efb;
    letter-spacing: 0;
    font-weight: 800;
    line-height: 1.8rem;
    color: #fff;
    font-size: 14px;
    height: 35px;
    min-width: 300px;
    &:hover {
      border: 4px solid #147efb;
      background: #147efb;
      color: white;
    }

    &.hide {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
    @media only screen and (max-width: 1270px) and (min-width: 1101px) {
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }
  .sign-up-button {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 4px solid #147efb;
    background: #fff;
    letter-spacing: 0;
    font-weight: 800;
    line-height: 1.8rem;
    color: #000;
    font-size: 14px;
    height: 35px;
    min-width: 300px;
    &:hover {
      border: 4px solid #147efb;
      background: #147efb;
      color: white;
    }

    &.hide {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }

    @media only screen and (max-width: 1100px) {
      border: 4px solid #147efb;
    }
    @media only screen and (max-width: 1270px) and (min-width: 1101px) {
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }
  .login-button {
    border: 4px solid #147efb;
    background: #fff;
    letter-spacing: 0;
    font-weight: 800;
    line-height: 1.8rem;
    color: #000;
    font-size: 14px;
    height: 35px;
    min-width: 300px;
    &:hover {
      border: 4px solid #147efb;
      background: #147efb;
      color: white;
    }

    &.hide {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }

    @media only screen and (max-width: 1100px) {
      border: 4px solid #147efb;
    }
    @media only screen and (max-width: 1270px) and (min-width: 1101px) {
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }
`

export const NavButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1270px) and (min-width: 1101px) {
    flex-direction: column;
  }
  .request-button,
  .login-button {
    border: 4px solid #147efb;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 1.8rem;
    font-size: 1.6rem;
    height: 40px;
    min-width: 140px;
    margin-right: 1rem;
    &:hover {
      border: 4px solid #147efb;
      background: #147efb;
      color: white;
    }

    &.hide {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
    @media only screen and (max-width: 1270px) and (min-width: 1101px) {
      margin-bottom: 1rem;
      margin-right: 0rem;
    }
  }

  .request-button {
    background: #147efb;
    color: #ffffff;
  }

  .login-button {
    background: #ffffff;
    color: #147efb;
  }

  .dropdown-button {
    &.hide {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
  }
`
