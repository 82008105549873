import styled from 'styled-components';

export const Container = styled.section`
  scroll-snap-type: ${({ disabled }) => disabled ? 'none' : 'y mandatory'};
  min-height: 100%;
  overflow-y: scroll;
  
  & > * {
    scroll-snap-align: ${({ disabled }) => disabled ? 'none' : 'start end'};
  }
`;
