import styled from 'styled-components';

export const Button = styled.button`
  min-width: 190px;
  height: 45px;
  border-radius: 50px;
  cursor: pointer;
  border: 4px solid #147efb;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  &:active {
    transform: translateY(5px);
  }

  &:focus {
    outline: none;
  }
`;
